
.LibraryViewController {
	
	.ContentViewWrapper > .ContentView {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		& > .Intro {
			width: 100%;
			padding: 50px 30px;
			box-sizing: border-box;
			font-size: 22px;
			opacity: 0.5;
			font-weight: bold;
			text-shadow: 0 -1px 0 #000000aa;

			i {
				font-size: 80px;
				margin-bottom: 15px;
			}

			p {
				margin-top: 10px;
				font-size: 18px;
				font-weight: normal;
				opacity: 0.7;
			}
		}

		& > .Items {
			width: 100%;
			box-sizing: border-box;
		}

		& > .Status {
			padding: 15px 0;
			& > .NewCloudLinkButton {
				text-transform: uppercase;
				font-weight: bold;
				padding: 6px 10px;
				background: #00000033;
				display: inline-block;
				border-radius: 8px;
				font-size: 14px;
				margin-bottom: 10px;
				i {
					margin-right: 5px;
				}
			}
			& > .CloudUnreachable {
				color: #ff7777;
				margin-bottom: 10px;
				i {
					margin-right: 5px;
				}
			}
			& > .Version {
				opacity: 0.4;
				font-weight: bold;
				font-size: 12px;
			}
		}

	}

}

.CloudLinkViewController {

	.ContentViewWrapper > .ContentView {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.Info {
		font-size: 15px;
		width: 200px;
		margin: 0 auto;
		opacity: 0.8;
	}

	.Code {
		font-weight: bold;
		font-family: 'Courier New', Courier, monospace;
		font-size: 40px;
	}

}
