* {
	margin: 0;
	padding: 0;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	box-sizing: border-box;
}

html, body {
	height: 100%;
	overflow: hidden;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: yellow;
	color: #f2f4fc;
}

$color-gray: #6a6d7d;
$color-highlight: #7ab9ff;

$common-margin: 15px;
$common-radius: 10px;

@mixin buttonColorDef($color) {

	background: linear-gradient($color, darken($color, 10%));

	&.pressed {
		background: linear-gradient(darken($color, 30%), darken($color, 30%));
	}

}

@mixin buttonBorder() {
	border-radius: $common-radius;
	box-shadow: 0 1px 3px #00000033, inset 0 1px 0 #ffffff11;
	border: 1px solid #343745;
}

/*
	TODO: Button Group loses shadow inset (shine)
	TODO: Button Group various colors ugly transion
*/

@keyframes viewControllerOpen {
	0% {
		transform: translate3d(100%, 0, 0);
	}	
	100% {
		transform: translate3d(0%, 0, 0);
	}
}

@keyframes viewControllerClose {
	0% {
		transform: translate3d(0%, 0, 0);
	}	
	100% {
		transform: translate3d(100%, 0, 0);
	}
}

// body {
// 	background: yellow;
// 	.NavigationController {
// 		background: red;
// 		& > .ViewController {
// 			background: blue;
// 		}
// 	}
// }

.NavigationController {

	&.pushing > .ViewController:last-child,
	&.popping > .ViewController:last-child {
		animation: 0.3s ease 1 viewControllerOpen;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	}

	&.popping > .ViewController:last-child {
		animation-fill-mode: forwards;
		animation-name: viewControllerClose !important;
	}

	& > .ViewController.drawsHeaderBackground > .Wrapper {
	
		.Header {
			background: linear-gradient(#484b5bdd, #343745dd);
			border-bottom: 1px solid #5a5d6fdd;
			& > .ContentView > .Title {
				visibility: visible;
			}
		}
	}

	& > .ViewController {
		text-align: center;
		position: absolute;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		background: #484b5b;
		z-index: 9000;

		.TopInset, .BottomInset {
			// background: yellow;
			// opacity: 0.1;
			flex-shrink: 0;
		}

		.TopInset {
			margin-top: 10px;
		}

		& > .Wrapper {
			height: 100%;
			display: flex;
			flex-direction: column;

			& > .Header {
				background: #484b5b;
				border-bottom: none;
				z-index: 100000;
				backdrop-filter: blur(2px);
				position: sticky;
				left: 0;
				right: 0;
				top: 0;
					
				& > .ContentView {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-bottom: 14px;
	
					& > .LeftButton,
					& > .RightButton {
						font-size: 24px;
						margin: 0 12px;
						padding: 2px;
					}
	
					& > .Title {
						font-weight: 500;
						text-shadow: 0 -1px #222;
						font-size: 18px;
						visibility: hidden;
					}
				}
	
				&.Filler {
					position: relative;
					visibility: hidden;
				}
			}

			& > .ContentViewWrapper {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
			}

			& > .ContentViewWrapper > .ContentView {

				flex-grow: 1;

				& > .Items {
					padding: $common-margin;
	
					.Item {
						margin-bottom: $common-margin;

						&.loading {
							opacity: 0.3 !important;
						}

					}
	
					.LabelItem {
						border-radius: $common-radius;
						padding: 15px 10px;
						box-shadow: 0 1px 3px #00000033, inset 0 1px 0 #ffffff11;
						border: 1px solid #343745;
						display: flex;
						flex-direction: column;
						justify-content: center;
						min-width: 0;
	
						.Value {
							font-size: 35px;
							margin-bottom: 4px;
						}
	
						.Label {
							font-weight: 700;
							opacity: 0.5;
						}
					}
	
					.ButtonItem {
						@include buttonBorder();
	
						padding: 15px 10px;
						display: flex;
						align-items: center;
						
						position: relative;
	
						@include buttonColorDef($color-gray);
	
						&.highlighted {
							@include buttonColorDef($color-highlight);
						}
	
						.Icon {
							font-size: 30px;
							opacity: 0.8;
							text-shadow: 0 -1px #222;
						}
	
						.Label {
							font-weight: 500;
							opacity: 0.8;
							text-shadow: 0 -1px #222;
							padding: 0 6px;
						}
	
						.Disclosure {
							position: absolute;
							right: 18px;
							font-size: 24px;
							text-shadow: 0 -1px #222;
						}
					}
	
					.ToggleItem {
						padding: 15px 0px;
						display: flex;
						align-items: center;
	
						.Icon {
							font-size: 30px;
							opacity: 0.8;
							text-shadow: 0 -1px #222;
						}
	
						.Label {
							font-weight: 500;
							opacity: 0.8;
							text-shadow: 0 -1px #222;
							padding: 0 6px;
						}
	
						.Knob {
							border: 2px solid #888;
							position: absolute;
							right: 18px;
							width: 60px;
							padding: 2px;
							border-radius: 30px;
							transition: background-color 0.25s;
							transition: border-color 0.25s;
	
							.KnobCenter {
								height: 28px;
								width: 28px;
								background: #fff;
								border-radius: 14px;
								transition: margin-left 0.25s;
							}
						}
	
						&.isOn {
							.Knob {
								border-color: $color-highlight;
								background-color: $color-highlight;
								.KnobCenter {
									margin-left: 32px;
									box-shadow: 1px 0 8px #00000055;
								}
							}
						}
					}
	
					.RowItem {
						display: flex;
						margin-bottom: 0;
	
						.LabelItem,
						.ButtonItem {
							flex-direction: column;
							justify-content: center;
							flex-grow: 1;
							flex-shrink: 1;
							flex-basis: 0;
							margin-right: $common-margin;
	
							.Icon + .Label {
								margin-top: 5px;
							}
	
							&:last-child {
								margin-right: 0;
							}
						}
					}
	
					.ButtonGroupItem {
						@include buttonBorder();
						margin-bottom: $common-margin;
	
						.ButtonItem {
							margin-right: 0;
							margin-bottom: 0;
							border: 0;
							border-radius: 0;
							box-shadow: none;
	
							border-right: 1px solid #00000033;
							border-left: 1px solid #ffffff11;
	
							&:first-child {
								border-left: 0;
								border-top-left-radius: $common-radius;
								border-bottom-left-radius: $common-radius;
							}
	
							&:last-child {
								border-right: 0;
								border-top-right-radius: $common-radius;
								border-bottom-right-radius: $common-radius;
							}
						}
					}
	
					.HeadingItem {
						color: #fff;
						font-weight: 500;
						margin-bottom: 15px;
						text-align: left;
						text-shadow: 0 -1px #222;
						opacity: 0.8;
	
						.Icon {
							margin-right: 8px;
						}
	
						.rightLabel {
							float: right;
						}
	
						&:after {
							content: "";
							display: table;
							clear: both;
						}
					}
	
					.SliderItem {
						background: linear-gradient(darken($color-gray, 15%), darken($color-gray, 10%));
						box-shadow: 0 1px 3px #00000033;
						border: 1px solid #343745;
						border-radius: $common-radius;
						height: 20px;
						position: relative;
						margin-bottom: calc(#{$common-margin} + 8px);
	
						.Filler {
							border-radius: $common-radius;
							border-top-right-radius: 6px;
							border-bottom-right-radius: 6px;
							position: absolute;
							left: 0;
							top: 0;
							bottom: 0;
						}
	
						.Filler, .Knob { @include buttonColorDef($color-highlight); }
	
						.KnobContainer {
	
							position: absolute;
							left: 10px;
							right: 12px;
							top: 0;
							bottom: 0;
	
							.Knob {
								position: absolute;
								border-radius: 100px;
								width: 30px;
								height: 30px;
								margin-left: -15px;
								top: -7px;
								border: 3px solid #00000044;
								box-shadow: 0 1px 8px #00000033, inset 0 0 2px #00000033;
								z-index: 8000;
							}
	
						}
	
					}
				}

			}
		}
	}

}

@import 'ViewControllerStyles'